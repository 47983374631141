<template>
  <!-- 入库明细 -->
  <div class="module-wrapper m-stkIn-order-line">
    <!-- 表格组件 -->
    <znl-table
      ref="table"
      gridtype="base"
      box-class="m-stkIn-order-line"
      :showCheck="true"
      checkboxBindingKey="StkInLineGUID"
      :columns="columns"
      :item-source="itemSource"
      :totalField="['Amount','Qty']"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('StkInLine')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <span class="znl-header-text">· 入库明细</span>
        <znl-button style-type="mac" :height="22" v-if="hasRes('Return')" @click="onStkReturn()">
          <i class="iconfont icon-backout_btn_ic"></i>
          <span>采购退货</span>
        </znl-button>
        <znl-button style-type="mac" :height="22" v-if="hasRes('In')" @click="onStkIn()">
          <i class="iconfont icon-buy-wh_btn_ic"></i>
          <span>采购入库</span>
        </znl-button>
      </div>
    </znl-table>

    <!-- 销售出库 -->
    <stk-out-edit
      :editvisible="stkOutEditVisible"
      v-if="stkOutEditVisible"
      :edit-type="editType"
      @confirm="stkOutEditVisible=false"
      @close="stkOutEditVisible=false"
    ></stk-out-edit>

    <!-- 采购入库 -->
    <stk-in-edit
      :editvisible="stkInEditVisible"
      v-if="stkInEditVisible"
      :edit-type="editType"
      :title="stkInEditTitle"
      @confirm="stkInEditVisible=false"
      @close="stkInEditVisible=false"
    ></stk-in-edit>

    <!-- 退货 -->
    <ord-purchase-return-edit
      :editvisible="ordReturnEditVisible"
      v-if="ordReturnEditVisible"
      :edit-type="editType"
      @save-success="()=>{this.onRefresh()}"
      @confirm="ordReturnEditVisible=false"
      @close="ordReturnEditVisible=false"
    ></ord-purchase-return-edit>
  </div>
</template>

<script>
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import StkOutEdit from "@c_modules/Stk/StkOutEdit";
import StkInEdit from "@c_modules/Stk/StkInEdit";
import OrdPurchaseReturnEdit from "@c_modules/Purchase/OrderReturnEdit";
import { erpTableSetHandler } from "@scripts/methods/common";

const Config = {
  configURL: "StkInLine/GetConfig",
  resetParamConfigURL: "StkInLine/ResetParamConfig",
  orderConfigURL: "StkIn/GetConfig",
  searchURL: "StkInLine/InLineSearch"
};
export default {
  name: "StkInOrderLine",
  mixins: [getCommonDataMixin, erpTableSetHandler],
  components: {
    StkOutEdit,
    StkInEdit,
    OrdPurchaseReturnEdit
  },
  config: Config,
  data() {
    return {
      editType: "addNew",
      stkOutEditVisible: false,
      stkInEditVisible: false,
      ordReturnEditVisible: false,
      columns: [],
      itemSource: [],
      stkInAddVisible: false, // 是否显示入库编辑页面
      onrefeshAdd: true, // 是否刷新添加编辑
      stkInGUIDLine: [],
      resourceList: [],
      parameterConfig: [],
      orderResourceList: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount, // false 为非主账号
      stkInGUID: null,
      stkInEditTitle: "新增入库单"
      // headerMenus: [
      //   {
      //     icon: 'mu-table-reset',
      //     text: '重置列宽及位置',
      //     handle: e => {
      //       console.log(e, 'reset column')
      //     }
      //   }
      // ]
    };
  },
  async created() {},
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("stkInline", Config.configURL);
      }
      if (config.ResourceList && config.ResourceList.length > 0) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;

      //处理特殊权限
      if (!this.isMainAccount) {
        let viewRemark1 = this.hasRes("ViewRemark1");
        let viewRemark2 = this.hasRes("ViewRemark2");
        let viewRemark3 = this.hasRes("ViewRemark3");
        let noSeeReplaceModel = this.hasRes("NoSeeReplaceModel");
        _.remove(columns, item => {
          if (!viewRemark1 && item.BindField == "Remark")
            // 备注1
            return true;
          else if (!viewRemark2 && item.BindField == "Remark1")
            // 备注2
            return true;
          else if (!viewRemark3 && item.BindField == "Remark2")
            // 备注3
            return true;
          else if (noSeeReplaceModel && item.BindField == "ReplaceModel")
            // 替代型号
            return true;
        });
      }
      this.columns = columns;

      this.$refs.table.init();
      this.$nextTick(() => {
        this.$emit("page-loading", false);
      });
    },

    async onBindData(pageIndex = 1) {
      if (!this.hasValue(this.stkInGUID)) {
        return;
      }
      let data = _.extend({ StkInGUID: this.stkInGUID });
      return await this.$post(Config.searchURL, data, data => {
        this.itemSource = data === undefined ? [] : data;
      });
    },

    onHeadSearch(stkInGuid) {
      if (stkInGuid) {
        this.stkInGUID = stkInGuid;
        this.onBindData();
      }
    },
    onSelectNoStatusRow() {
      // 获取选中的行 先判断勾选再判断选中
      let rows = this.$refs.table.getCheckedRows();
      if (rows && rows.length > 0) {
        return rows.map(row => row.StkInLineGUID);
      } else {
        this.$message({
          message: "请至少勾选一条数据进行操作",
          type: "error"
        });
        return [];
      }
    },

    // 采购入库
    onStkIn() {
      let guids = this.onSelectNoStatusRow();
      if (guids.length > 0) {
        let paramOrder = { stkInGUIDLine: guids, IsReturn: false };
        this.$store.commit("setParamOrder", paramOrder);
        this.$nextTick(function() {
          this.$refs.table.cleanAllCheck();
          this.editType = "add";
          this.stkInEditVisible = true;
        });
      }
    },

    // 采购退货
    onStkReturn() {
      let rows = this.$refs.table.getCheckedRows();
      if (rows === null || rows === undefined || rows.length === 0) {
        this.$message({
          message: "请至少勾选一条数据进行操作",
          type: "error"
        });
        return false;
      }
      let msg = "";
      rows.some(row => {
        if (row.ReturnQty >= row.Qty) {
          msg = `型号${row.Model}已经全部退货，不能重复退货`;
          return true;
        }
        if (row.Status !== 3) {
          msg = `型号${row.Model}不是已入库状态，不能退货`;
          return true;
        }
      });
      if (msg !== "") {
        this.$message({ message: msg, type: "error" });
        return false;
      }
      let guids = rows.map(row => row.StkInLineGUID);
      let paramOrder = { stkInGUIDLine: guids, IsReturn: true };
      this.$store.commit("setParamOrder", paramOrder);
      this.$nextTick(function() {
        this.$refs.table.cleanAllCheck();
        this.editType = "edit";
        this.ordReturnEditVisible = true;
      });
    },

    onRefresh() {
      this.onBindData(1);
    },

    hasRes(code) {
      return _.includes(this.resourceList, code);
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("StkInLine", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("StkInLine", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("StkInLine", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("StkInLine");
    }
  }
};
</script>

<style lang="scss">
</style>
