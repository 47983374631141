<template>
  <znl-dialog
    :title="title"
    :visible="show"
    height="640px"
    width="1000px"
    :close-on-click-modal="false"
    :loading.sync="loading"
    :loading-text="elementLoadingText"
    :footer-height="50"
    :header-height="40"
    :is-footer-show="false"
    @close="
      (val) => {
        $emit('close', val);
      }
    "
    class="znl-account-edit"
  >
    <el-row style="margin-left: 6px">
      <el-col :span="18" class="frameSet">
        <label class="lb">登录账号</label>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入登录账号"
          size="mini"
          layout="left"
          width="100"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          type="text"
          v-model="account.Account"
        />
        <label class="lb">密码</label>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入登录密码"
          size="mini"
          layout="left"
          width="100"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          type="password"
          v-model="account.PWD"
        />
        <label class="lb">使用人姓名</label>
        <znl-input
          from-type="input"
          :disable="false"
          placeholder="请输入姓名"
          size="mini"
          layout="left"
          width="100"
          :height="26"
          inp-perc="100%"
          :clearable="true"
          :border="true"
          type="text"
          v-model="account.UserName"
        />
        <label class="lb">账号状态</label>
        <znl-input
          form-type="select"
          placeholder="状态"
          width="50"
          size="mini"
          layout="left"
          inp-perc="100%"
          :select-options="[
            { key: 1, value: '可用' },
            { key: 0, value: '禁用' },
          ]"
          v-model="account.ActiveStatus"
          :border="true"
        ></znl-input>
        <znl-button
          :height="22"
          :width="80"
          style-type="main"
          tip="保存"
          @click="save"
        >
          保存
        </znl-button>
      </el-col>
      <el-col :span="6" class="frameSet no-border">
        <el-checkbox v-model="chkAllPermission" @change="chkAllChanged">
          所有权限
        </el-checkbox>
        <el-dropdown trigger="click" @command="CopyPermission">
          <znl-button :height="22" style-type="mac" tip="保存子账号">
            从已有账号复制权限
          </znl-button>
          <el-dropdown-menu slot="dropdown" class="zl-dropdown">
            <el-dropdown-item
              v-for="item in accounts"
              :key="item.UserID"
              :command="item.UserID"
            >
              {{ item.UserName }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>

    <el-tabs
      class="tab-manage-tab nav-common-ui tab-btnstyle-box"
      style="height: 560px; margin: 2px -10px"
      v-model="selTab"
    >
      <el-tab-pane name="账号权限设置" label="账号权限设置" class="role-set">
        <div class="role-list">
          <div class="role-title">
            仅允许查看某个日期之后的数据（包括配单客户需求、出入库单、库存）
          </div>
          <div class="role-content">
            <span>客户需求：</span>
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="请选择日期"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="1"
              width="100px"
              v-model="account.OnlyViewRequestAfter"
              value-format="yyyy/MM/dd"
              format="yyyy/MM/dd"
            />

            <span>发货单：</span>
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="请选择日期"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="2"
              width="100px"
              v-model="account.OnlyViewStkOutAfter"
              value-format="yyyy/MM/dd"
              format="yyyy/MM/dd"
            />

            <span>入库单：</span>
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="请选择日期"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="3"
              width="100px"
              v-model="account.OnlyViewStkInAfter"
              value-format="yyyy/MM/dd"
              format="yyyy/MM/dd"
            />

            <span>库存：</span>
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="请选择日期"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="4"
              width="100px"
              v-model="account.OnlyViewStockAfter"
              value-format="yyyy/MM/dd"
              format="yyyy/MM/dd"
            />

            <el-tooltip
              effect="light"
              content="包含：电话历史、查价、预收付款"
              placement="bottom-start"
            >
              <span>其他：</span>
            </el-tooltip>
            <znl-input
              title-width="0px"
              layout="left"
              form-type="datepicker"
              placeholder="请选择日期"
              :readonly="false"
              :disabled="false"
              :border="true"
              :is-must-fill="false"
              tabindex="5"
              width="100px"
              v-model="account.OnlyViewAlterOf"
              value-format="yyyy/MM/dd"
              format="yyyy/MM/dd"
            />
          </div>
        </div>
        <div class="role-list">
          <div class="role-title">
            允许查看那些人数据（包括配单客户需求，报价，出入库单，客户及供应商资料，不勾选默认只能看自己的数据）
          </div>
          <div
            class="role-content"
            style="height: 60px; overflow-y: auto"
            ref="allowAccounts"
          >
            <el-checkbox-group v-model="account.arrAllowViewAccountIDs">
              <el-checkbox
                v-for="item in accounts"
                :key="item.UserID"
                :label="item.UserID.toString()"
              >
                {{ item.UserName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="role-list">
          <div class="role-title">所属角色</div>
          <div
            class="role-content"
            style="height: 40px; overflow-y: auto"
            ref="roleList"
          >
            <el-checkbox-group v-model="selRoles">
              <el-checkbox
                v-for="item in roles"
                :key="item.RoleID"
                :label="item.RoleID"
                @change="roleChanged(item.RoleID, $event)"
              >
                {{ item.RoleName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div style="height: 320px; overflow-y: auto" ref="permissionList">
          <el-checkbox-group v-model="account.modelusPermissionIDs">
            <div
              class="role-list"
              v-for="(m, idx) in modules"
              :key="m.ModuleCode"
            >
              <div class="role-title">
                {{ m.ModuleName
                }}<span v-if="idx == 0"
                  >(直接控制软件顶部的选项卡，若此处不做勾选，则其他权限将无效！)</span
                >
              </div>
              <div class="role-content">
                <el-checkbox
                  class="fix-width"
                  v-for="item in m.Items"
                  :key="item.ItemID"
                  :label="item.ItemID"
                  @change="
                    modeItemChange(
                      $event,
                      m.ModuleCode,
                      item.ItemID,
                      item.ItemName
                    )
                  "
                >
                  {{ item.ItemName }}
                </el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="登录设置" class="login-set">
        <el-row style="margin-top: 10px;">
          <el-col :span="13" :offset="1">
            限定时间登录 从
            <znl-input
              from-type="input"
              :disable="false"
              placeholder="09:00"
              size="mini"
              layout="left"
              width="60"
              :height="26"
              inp-perc="100%"
              :clearable="true"
              :border="true"
              type="text"
              v-model="account.UseTimeBegin"
            />
            至
            <znl-input
              from-type="input"
              :disable="false"
              placeholder="18:00"
              size="mini"
              layout="left"
              width="60"
              :height="26"
              inp-perc="100%"
              :clearable="true"
              :border="true"
              type="text"
              v-model="account.UseTimeOver"
            />
            每天登录的时间
          </el-col>
          <el-col :span="10">
            <el-checkbox v-model="account.IsDisMobileLogin">禁止手机登录</el-checkbox>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1" style="color: #aaa;">
            <span style="color: #333;font-size: 16px">可登录电脑机器列表</span>(帐号在某台机器上登录过，会自动添加到列表中，也可以手动添加，刪除。CpuID、硬盘序列号和Mac地址指定一台机机器）
            <br>您可以钩选此子帐号允许登录或禁用登录的机器，指定别名更容易识别每一台机器。
            如何查看CpuID...
            如何查看硬盘序列号...
            如何查看Mac地址...
          </el-col>
        </el-row>
      </el-tab-pane> -->
      <el-tab-pane label="账号详细信息" class="info">
        <el-row>
          <el-col :span="12">
            <el-row>
              <el-col class="lb" :span="6">部门</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.DeptName"
                />
              </el-col>
              <el-col :span="6" class="lb">所属公司</el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">联系电话</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.Telephone"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">传真</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.Fax"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">手机号</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.Mobile"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">QQ</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.QQ"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">Email</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.Email"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">出生日期</el-col>
              <el-col :span="12">
                <znl-input
                  title-width="0px"
                  layout="left"
                  form-type="datepicker"
                  placeholder="出生日期"
                  :readonly="false"
                  :disabled="false"
                  :border="true"
                  :is-must-fill="false"
                  tabindex="8"
                  width="150px"
                  v-model="account.Birthday"
                />
              </el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">身份证号</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.IDCardNumber"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">家庭住址</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.FamilyAddress"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">社保号</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.SocialSecurityNo"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">紧急联系人电话</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.EmergencyContact"
              /></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <el-row>
              <el-col class="lb" :span="6">员工单据代码</el-col>
              <el-col :span="12">
                <znl-input
                  from-type="input"
                  :disable="false"
                  placeholder=""
                  size="mini"
                  layout="left"
                  width="100%"
                  :height="26"
                  inp-perc="100%"
                  :clearable="true"
                  :border="true"
                  type="text"
                  v-model="account.BillNoUserCode"
              /></el-col>
              <el-col :span="6" class="lb" style="text-align: left"
                >两位英文字母</el-col
              >
            </el-row>
          </el-col>
          <el-col :span="12" style="max-height: 500px; overflow: auto">
            <el-checkbox-group v-model="account.arrBelongSubCompanyIDs">
              <div v-for="item in subCompanys" :key="item.SubCompanyID">
                <el-checkbox :label="item.SubCompanyID.toString()">
                  {{ item.SubCompanyName }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="仓库权限" class="warehouse">
        <el-row :gutter="20">
          <el-col :span="10">
            <!-- <znl-button
              :height="22"
              :width="100"
              style-type="mac"
              tip="保存子账号"
              class="box-left-btn search-btn"
            >
              <i class="iconfont icon-add"></i>
              添加仓库
            </znl-button> -->
            <!-- 仓库表格 -->
            <znl-gridmodule
              ref="wareGrid"
              gridtype="action"
              :min-height="500"
              :max-height="500"
              :grid-show="warehouse.gridShow"
              :on-init="whOnInit"
              :columns="warehouse.columns"
              :item-source="warehouse.itemSource"
              :enter-cellmoves-direction="true"
              :is-multi-rows-check="false"
              pagination-position="bottom"
              :is-show-rightbtns="false"
              :on-save-row="whOnSaveRows"
              :on-refresh="whOnRefresh"
              @edit:cell-ended="whOnCellEdited"
              @-current-row-change="whOnCurrRowChange"
              @cell-click="whOnCellClick"
              @-ondblclick=""
            >
              <div slot="heading-slot" class="account-mng-grid-heading-slot">
                <znl-button
                  @click="$refs.wareGrid.addRow(true)"
                  :height="22"
                  :width="100"
                  style="margin: 6px 0"
                  style-type="mac"
                  tip="保存子账号"
                  class="box-left-btn search-btn"
                >
                  <i class="iconfont icon-add"></i>
                  添加仓库
                </znl-button>
              </div>
            </znl-gridmodule>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-checkbox
                v-model="chkWarehouseJurisdiction"
                @change="chkWarehouseJurisdictionChange"
              >
                启动仓库权限设置
              </el-checkbox>
            </el-row>
            <el-row>
              <div>说明：</div>
              <div style="font-size: 12px">
                1、输入仓库名称，按回车键自动保存仓库<br />
                2、勾选启用仓库权限控制，权限设置才能生效<br />
                3、勾选相应仓库，则给此账号分配了相应仓库的数据权限<br />
              </div>
            </el-row>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </znl-dialog>
</template>

<script>
import { mixin as znlMethodsMixin } from "@c_common/znlGrid/scripts/znlMethods"; //通用表格grdmodule mixin

export default {
  name: "AccountEdit",
  // components: { znlMethodsMixin },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "添加子账号",
    },
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Object,
    },
    // modelusPermissionIDs: {
    //   type: Array,
    //   default: [],
    // }
  },
  data() {
    return {
      loading: true, //载入中
      elementLoadingText: "正在保存数据,请稍后...", //载入中文字
      showPage: false, //显示内容页

      selTab: "账号权限设置", //选中的Tab
      modules: [], //模块数据
      permissions: [], //所有权限
      modMaps: [], //权限对应关系
      roles: [], //角色数据
      accounts: [], //账号列表
      subCompanys: [], //子公司列表
      warehouses: [], //仓库列表
      chkAllPermission: false,
      selRoles: [], //选择的角色
      chkWarehouseJurisdiction: false, //选择启动仓库权限

      //#region defaultAccount
      // defaultAccount:  {
      //   "MainAccountID": 0, //主账号？
      //   "ActiveStatus": "可用", //状态
      //   "IsBind": false, //
      //   "DontAllowNegativeStock": false,
      //   "StartAutoBackupData": false,
      //   "LoginSucceed": 0,
      //   "UserID": 0, //用户ID, 主键
      //   "UserGUID": "", //Guid
      //   "CompanyID": "", //公司ID
      //   "Account": "", //登录账号
      //   "UserName": "", //使用人姓名
      //   "PWD": "", //密码
      //   "Email": "",
      //   "Mobile": "",
      //   "Telephone": "",
      //   "Fax": "",
      //   "QQ": "",
      //   "DeptName": "",
      //   "IsMainAccount": 1, //是否主账号？
      //   "UseTimeBegin": "",
      //   "UseTimeOver": "",
      //   "MacAddress": "",
      //   "HardDiskSN": "",
      //   "FamilyAddress": "",
      //   "IDCardNumber": "",
      //   "SocialSecurityNo": "",
      //   "EmergencyContact": "",
      //   "SignInTimes": 1,
      //   "LastSignTime": "",
      //   "LastSignIP": "",
      //   "IsActive": 2,
      //   "CreatedTime": "",
      //   "CreatedByID": 0,
      //   "CreatedByName": "",
      //   "BillNoUserCode": "",
      //   "IsDisMobileLogin": false,
      //   "OnlyViewAlterOf": "",
      //   "CpuID": "",

      //   "BelongSubCompanyIDs": "", //所属公司IDs
      //   "AllowViewAccountIDs": "", //查看哪些人数据IDs
      //   "WarehouseIDs": "63,82,103", //仓库IDs
      // },
      //#endregion

      warehouse: {
        gridShow: true,
        columns: [],
        itemSource: [],
        // pageSize: 30,
        // pageIndex: 1,
        totalCount: 3,
      },
    };
  },
  computed: {
    warehouseData() {
      let d = this.account.arrWarehouseIDs || [];

      return this.warehouses.map((item) => {
        return {
          ...item,
          ...{
            HasRight:
              d.findIndex(
                (it) => it == item.WarehouseId
              ) > -1,
          },
        };
      });
    },
  },
  watch: {
    show(val, oldVal) {
      console.log("show modified", val);
      if (val) {
        //显示，有些界面上的初始化
        this.selTab = "账号权限设置";
        this.$nextTick(() => {
          //滚动条滚动到最初位置

          if (this.$refs.allowAccounts) this.$refs.allowAccounts.scrollTop = 0;
          if (this.$refs.roleList) this.$refs.roleList.scrollTop = 0;
          if (this.$refs.permissionList)
            this.$refs.permissionList.scrollTop = 0;
        });
        if (this.$refs.wareGrid) this.whOnBindData();
        this.selRoles = [];

        this.bindWarehouseJurisdiction(); //绑定仓库权限
      }
    },
  },
  methods: {
    async bindWarehouseJurisdiction() {
      //绑定仓库权限设置
      // const awWarehouseJurisdiction = await this.$post("SystemConfig/GetSystemConfig", {});
      // this.chkWarehouseJurisdiction = awWarehouseJurisdiction.SwitchControl.WarehouseJurisdiction;
      this.chkWarehouseJurisdiction = (
        await this.$post("SystemConfig/GetSystemConfig", {})
      ).SwitchControl.WarehouseJurisdiction;
    },
    async chkWarehouseJurisdictionChange(checked) {
      //修改仓库权限设置
      // console.log(checked);
      await this.$post("SystemConfig/SaveSystemConfig", {
        SwitchControl: {
          WarehouseJurisdiction: checked,
        },
      });
    },
    async modeItemChange(checked, moduleCode, permissionId, permissionName) {
      // console.log(checked,moduleCode,permissionId,permissionName, this.permissions);
      // console.log('权限对应关系', this.modMap());

      if (moduleCode == "MOD") {
        //点击模块联动其所属的权限
        let m = this.modMaps.find((mod) => mod.ItemID == permissionId);
        if (m) {
          m.Items.map((it) => {
            let fidx = this.account.modelusPermissionIDs.findIndex(
              (f) => f == it
            );
            if (fidx > -1) {
              if (!checked) this.account.modelusPermissionIDs.splice(fidx, 1);
            } else {
              if (checked) this.account.modelusPermissionIDs.push(it);
            }
          });
        }
      } else {
        //点击具体权限联动模块
        // console.log(this.permissions);
        let f = this.modMaps.find(
          (mod) => mod.Items.indexOf(permissionId) > -1
        );
        if (f) {
          let fidx = this.account.modelusPermissionIDs.findIndex(
            (fd) => fd == f.ItemID
          );
          if (fidx > -1) {
            // 不需要取消选择
            // if (!checked) this.account.modelusPermissionIDs.splice(fidx, 1);
          } else {
            if (checked) this.account.modelusPermissionIDs.push(f.ItemID);
          }
        }
      }
    },
    modMap() {
      // console.log(this.modules);
      return this.modules[0].Items.map((mod) => {
        let midx = -1;
        let start = -1; //start index if > -1
        let num = 0;
        switch (mod.ItemName) {
          case "市场查货":
            midx = 1;
            break;
          case "库存管理":
            midx = 2;
            break;
          case "配单管理":
            midx = 3;
            break;
          case "单据管理":
            midx = 4;
            break;
          case "销售管理":
            midx = 5;
            break;
          case "采购管理":
            midx = 6;
            break;
          case "客户管理":
            midx = 7;
            start = 0;
            num = 7;
            break;
          case "供应商管理":
            midx = 7;
            start = 7;
            num = 8;
            break;
          case "业务统计":
            midx = 8;
            break;
          case "财务管理":
            midx = 9;
            break;
          case "行业工具":
            midx = -1;
            break;
          case "供应链管理":
            midx = -1;
            break;
          case "交易中心":
            midx = -1;
            break;
        }
        let pArr = [];
        if (midx > 0) {
          pArr = this.modules[midx].Items.map((it) => it.ItemID);
          if (start > -1 && num > 0) {
            pArr = pArr.slice(start, start + num);
          }
        }
        return {
          ...mod,
          Items: pArr,
        };
      });
    },
    async CopyPermission(cmd) {
      let f = this.accounts.find((item) => item.UserID.toString() == cmd);
      if (f) {
        this.account.modelusPermissionIDs = f.Items;
        // this.account.modelusPermissionIDs.splice(0, this.modelusPermissionIDs.length)
        // f.Item.map(it=>this.account.modelusPermissionIDs.push(it))
        this.$message({
          message: `已从 ${f.UserName} 复制账号权限`,
          type: "success",
        });
      }
    },
    async save() {
      if(!this.account.arrAllowViewAccountIDs) this.account.arrAllowViewAccountIDs = [];
      if(!this.account.arrBelongSubCompanyIDs) this.account.arrBelongSubCompanyIDs = [];
      if(!this.account.arrWarehouseIDs) this.account.arrWarehouseIDs = [];
      this.account.AllowViewAccountIDs = this.account.arrAllowViewAccountIDs.join(",");
      this.account.BelongSubCompanyIDs = this.account.arrBelongSubCompanyIDs.join(",");
      this.account.WarehouseIDs = this.account.arrWarehouseIDs.join(",");
      this.account.IsActive = this.account.ActiveStatus == 0 ? 1: 2;

      //保存account
      this.$post("SysAccount/SaveAccount", this.account, (data, logic) => {
        if (logic && logic.code == 200) {
          this.$message({
            message: `子账号保存成功！ `,
            type: "success",
          });
          //保存权限
          //组合数据
          let pdata = this.account.modelusPermissionIDs.map((item) => {
            return {
              UserID: data.UserID,
              RoleID: item,
            };
          });
          //保存
          this.$post("SysAccount/SaveUserRoleItem", pdata, () => {
            this.$emit("account-edited");
            this.$emit("close", true);
          });
          //保存仅允许查看某个日期之后的数据权限
          let onlyViewAfters = [];
          onlyViewAfters.push({
            UserGuid: this.account.UserGUID,
            SCode: "OnlyViewRequestAfter",
            SName: "仅查看指定日期之后的客户需求单数据",
            SDataType: "DateTime",
            SValue: this.account.OnlyViewRequestAfter,
            SRemark: "仅查看指定日期之后的客户需求单数据",
          });
          onlyViewAfters.push({
            UserGuid: this.account.UserGUID,
            SCode: "OnlyViewStkOutAfter",
            SName: "仅查看指定日期之后的发货单数据",
            SDataType: "DateTime",
            SValue: this.account.OnlyViewStkOutAfter,
            SRemark: "仅查看指定日期之后的发货单数据",
          });
          onlyViewAfters.push({
            UserGuid: this.account.UserGUID,
            SCode: "OnlyViewStkInAfter",
            SName: "仅查看指定日期之后的入库单数据",
            SDataType: "DateTime",
            SValue: this.account.OnlyViewStkInAfter,
            SRemark: "仅查看指定日期之后的入库单数据",
          });
          onlyViewAfters.push({
            UserGuid: this.account.UserGUID,
            SCode: "OnlyViewStockAfter",
            SName: "仅查看指定日期之后的库存数据",
            SDataType: "DateTime",
            SValue: this.account.OnlyViewStockAfter,
            SRemark: "仅查看指定日期之后的库存数据",
          });
          this.$post("UserSetup/SaveUserSetup", onlyViewAfters);
        } else {
          this.$message({
            message: `子账号保存错误。 ${logic ? logic.msg : ""}`,
            type: "error",
          });
        }
      });
    },
    async chkAllChanged(checked) {
      let sel = [...this.permissions];

      if (checked) {
        sel = sel.filter((item) => {
          return (
            item.ItemName != "不看替代型号" &&
            item.ItemName != "不允许采购看利润" &&
            item.ItemName != "不允许出库" &&
            item.ItemName != "不修改销售价" &&
            item.ModuleCode != "SPC"
          );
        });
      } else {
        sel = sel.filter((item) => {
          return (
            item.ItemName == "不看替代型号" ||
            item.ItemName == "不允许采购看利润" ||
            item.ItemName == "不允许出库" ||
            item.ItemName == "不修改销售价" ||
            item.ModuleCode == "SPC"
          );
        });
      }

      this.account.modelusPermissionIDs = sel.map((i) => i.ItemID);
    },
    async roleChanged(roleID, checked) {
      if (checked) {
        let sel = this.roles.find((item) => item.RoleID == roleID);
        if (sel) {
          this.account.modelusPermissionIDs = _.union(
            this.account.modelusPermissionIDs,
            sel.DictItemIDList
          );
        }
      }
    },
    async whOnInit() {
      this.warehouse.columns = [
        {
          binding: "HasRight",
          name: "HasRight",
          header: "分配权限",
          visible: true,
          dataType: 4,
          width: 100,
          isReadOnly: true,
          isFieldSearch: false,
          isSystem: true,
          isCheckedColumn: true,
        },
        {
          binding: "WarehouseName",
          name: "WarehouseName",
          header: "仓库名称",
          visible: true,
          dataType: 0,
          width: 200,
          isReadOnly: false,
          isFieldSearch: false,
          isSystem: false,
        },
        {
          name: "del",
          dataType: 5,
          binding: "del",
          width: 36,
          header: "",
          visible: true,
          allowSorting: false,
          isReadOnly: true,
          editable: false,
          isFieldSearch: false,
          isSystem: true,
          buttons: [
            {
              className: "znl-table-btn",
              content: (d) => {
                return "<a>删除</a>";
              },
              click: (row) => {
                this.whOnDelete(row);
              },
            },
          ],
        },
      ];
      this.whOnBindData();
    },
    async whOnRefresh() {
      // console.log("onBindData", this.account.arrWarehouseIDs);
      this.whOnBindData.onBindData();
    },
    async whOnBindData() {
      // console.log("onBindData", this.account.arrWarehouseIDs);

      this.warehouse.itemSource = this.warehouseData;
      // this.warehouse.itemSource = [
      //   {
      //     WarehouseId: "001",
      //     WarehouseName: "华强北",
      //     HasRight: true
      //   },
      // ];
    },
    async whOnSaveRows(row) {
      // console.log("onSaveRows", row);
    },
    async whOnDelete(row) {
      if (
        "confirm" ==
        (await this.$confirm("您确认删除此仓库吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }))
      ) {
        let awDeleteWarehouse = await this.$post("SysAccount/DeleteWarehouse", [
          row,
        ]);
        let f = this.warehouses.findIndex(
          (ws) => ws.WarehouseId == row.WarehouseId
        );
        if (f > -1) {
          this.warehouses.splice(f, 1);
        }
        this.whOnBindData();
      }
    },
    async whOnCellEdited(
      afterValue,
      beforeValue,
      colName,
      gridObj,
      rowIndex,
      colIndex
    ) {
      // 1.修改后值 2.修改前值 3.列名 4.表格对象 5.行索引 6.列索引 => {
      //console.log('onCellEdited. ', afterValue, beforeValue, colName, gridObj, rowIndex, colIndex);
      //编辑后立即更新
      if (colName == "WarehouseName" && afterValue != beforeValue) {
        let awSaveWarehouse = await this.$post("SysAccount/SaveWarehouse", [
          {
            WarehouseId: gridObj.rows[rowIndex].WarehouseId,
            WarehouseName: afterValue,
            CompanyID: gridObj.rows[rowIndex].CompanyID,
          },
        ]);
        //如果是新增，保存后更新的信息
        // if(!gridObj.rows[rowIndex].WarehouseId && awSaveWarehouse.length > 0){
        // gridObj.rows[rowIndex].WarehouseId = awSaveWarehouse[0].WarehouseId
        // gridObj.rows[rowIndex].CompanyID = awSaveWarehouse[0].CompanyID

        awSaveWarehouse.map((w) => {
          let f = this.warehouses.findIndex(
            (ws) => ws.WarehouseId == w.WarehouseId
          );
          if (f > -1) {
            this.warehouses.splice(f, 1, w);
          } else {
            this.warehouses.push(w);
          }
        });

        // this.warehouses = _.unionBy(this.warehouses, awSaveWarehouse, 'WarehouseId')
        // console.log(awSaveWarehouse, this.warehouses);
        // }
        this.$nextTick(() => {
          // this.$refs.wareGrid.refresh();
        });
        this.whOnBindData();

        // this.$refs.wareGrid.reFMHeight();
        // this.warehouse.onSaveRows();
      }
    },
    async whOnCurrRowChange(row) {
      // 1.行对象 => {
      // console.log("onCurrRowChanged. ", row);
    },
    async whOnCellClick(row, column, cell, event) {
      // 1.行对象 2.列对象 3.单元格 4.时间参数 => {
      // console.log('onCellClick. ', row, column, cell, event,);

      if (
        column.property == "HasRight" &&
        event.target.className == "el-checkbox__original"
      ) {
        // console.log("设置选择仓库", column.property, event);
        //更新是否选择
        let f = this.account.arrWarehouseIDs.findIndex(
          (item) => item == row.WarehouseId
        );
        if (f > -1) {
          if (row.HasRight) {
            this.account.arrWarehouseIDs.splice(f, 1);
          }
        } else {
          if (!row.HasRight) {
            this.account.arrWarehouseIDs.push(row.WarehouseId);
          }
        }
        this.whOnBindData();
      }
    },
  },
  created() {},
  async mounted() {
    //取得所有模块权限
    const awGetAllPermissionConfig = this.$post(
      "SysAccount/GetAllPermissionConfig",
      {}
    );
    //取得所有角色
    const awaitGetAllUserRoleClient = this.$post(
      "SysAccount/GetAllUserRoleClient",
      {}
    );
    //取得所有账号（简略）
    const awGetAllowViewAccounts = this.$post(
      "SysAccount/GetAllowViewAccounts",
      {}
    );
    //获取所有子公司（用于选择所属子公司）
    const awGetSubCompany = this.$post("SysAccount/GetSubCompany", {});
    //获取仓库列表
    const awGetWarehouses = this.$post("SysAccount/GetWarehouses", {});

    this.modules = await awGetAllPermissionConfig;
    this.roles = await awaitGetAllUserRoleClient;
    this.accounts = await awGetAllowViewAccounts;
    this.subCompanys = await awGetSubCompany;
    this.warehouses = await awGetWarehouses;

    //计算所有的权限
    // for(let p of this.modules.map(item=>{return item.Items}))
    //   this.permissions = [...this.permissions, ...p];
    for (let m of this.modules)
      for (let p of m.Items)
        this.permissions.push({
          ModuleCode: m.ModuleCode,
          ModuleName: m.ModuleName,
          ...p,
        });
    //计算权限对应关系
    this.modMaps = this.modMap();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/zl/zl.scss";
* {
  box-sizing: border-box;
}

.frameSet {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  > * {
    margin-left: 10px;
  }
  .lb {
    font-size: 13px;
  }
}
.no-border {
  border: 0;
}

//权限列表
//变量
$RoleColor: rgb(90, 128, 211);
$RoleBorderColor: rgb(90, 128, 211);
$RoleBgColor: #eee;

.role-set {
  .role-list {
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 6px;
    .role-title {
      font-weight: 500;
      color: $RoleColor;
      border: 1px $RoleBorderColor solid;
      border-radius: 4px;
      padding: 2px;
      margin-left: 2px;
      z-index: 100;
      background-color: $RoleBgColor;
    }
    .role-content {
      border: 1px $RoleBorderColor solid;
      padding: 4px;
      border-radius: 4px;
      margin-top: -10px;
      padding-top: 12px;
      align-self: stretch;

      label.el-checkbox {
        &:first-child {
          margin: 0 5px !important;
        }

        &.fix-width {
          width: 150px;
        }
      }
    }
  }
}

.info {
  .lb {
    font-size: 13px;
    line-height: 28px;
    text-align: right;
    padding: 2px 10px;
  }
}

.login-set {
  font-size: 13px;
}

.zl-dropdown {
  height: 500px;
  overflow: auto;
  /* top: 94px !important; */
}
</style>
<style lang="scss">
//账号编辑窗口
.znl-dialog__wrapper.znl-account-edit {
  //关闭按钮
  .znl-dialog__headerbtn {
    right: 10px;
  }

  .tab-btnstyle-box > .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll {
    height: auto;
  }

  //修复时间选择框
  .login-set .el-date-editor .el-range-separator {
    width: auto;
  }
  /* .role-content>label.el-checkbox{
    &:first-child{
      margin: 0 5px !important;
    }
  } */
}
</style>
